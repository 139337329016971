// Colors
// =======================================================================
/* stylelint-disable color-no-hex */
/* stylelint-disable */
$color-1-50:            #FBF2F3;
$color-1-100:           #FFDBDF;
$color-1-300:           #F87882;
$color-1-500:           #E91820;
$color-1-700:           #C6001A;
$color-1-900:           #AC0004;

$color-2-50:            #F3F1F2;
$color-2-100:           #D5D0D1;
$color-2-300:           #A9A4A5;
$color-2-500:           #7D7678;
$color-2-700:           #504B4C;
$color-2-900:           #231F20;

$white:           #fff;
$grey-1-50:             #F6F6F9;
$grey-1-50:             #F6F6F9;
$grey-1-100:            #C5C8D8;
$grey-1-300:            #939AB3;
$grey-1-500:            #515975;
$grey-1-700:            #202F46;
$grey-1-900:            #101723;
$black:                 #100E0F;

$support-info-50:       #E5F5FF;
$support-info-100:      #A7DAFF;
$support-info-300:      #63BEFF;
$support-info-500:      #0F77D9;
$support-info-700:      #0B5499;
$support-info-900:      #073A69;

$support-success-50:    #E0FFD1;
$support-success-100:   #B7FB98;
$support-success-300:   #7DE74B;
$support-success-500:   #138321;
$support-success-700:   #0C5515;
$support-success-900:   #224412;

$support-error-50:      #FFEAE5;
$support-error-100:     #FFC7C7;
$support-error-300:     #FF5D5D;
$support-error-500:     #DA0404;
$support-error-700:     #8A1616;
$support-error-900:     #5C1818;

$support-warning-50:    #FFFBE5;
$support-warning-100:   #FFE7AB;
$support-warning-300:   #FFD56A;
$support-warning-500:   #F5B100;
$support-warning-700:   #926E13;
$support-warning-900:   #65501B;
/* stylelint-enable color-no-hex */

// Layout colours
$bg-theme-1: $white;
$bg-theme-2: $color-2-300;
$bg-theme-3: $color-1-50;


// TBD not in use yet in Figma
// $page-bg:                 $white;
// $dark-accent-bg:          $color-1-100;
// $light-accent-bg:         $color-2-50;
// $light-grey-bg:           $grey-1-50;
// $global-header:           $color-1-500;
// $img-load-bg:             $color-2-50;
// $border-color:            $grey-1-100;

// Background Themes
@mixin bg-theme-1 {
    background-color: $bg-theme-1;
}

.bg-theme-1 {
    @include bg-theme-1;
}

@mixin bg-theme-2 {
    background-color: $bg-theme-2;
}

.bg-theme-2 {
    @include bg-theme-2;
}

@mixin bg-theme-3 {
    background-color: $bg-theme-3;
}

.bg-theme-3 {
    @include bg-theme-3;
}


// Colour maps - USED FOR STYLE GUIDE ONLY
// NOTE: please update templates/styleguide/core-elements/colours.hbs if adding/removing colours during project setup
$primary-colors: (
    color-1-50: $color-1-50,
    color-1-100: $color-1-100,
    color-1-300: $color-1-300,
    color-1-500: $color-1-500,
    color-1-700: $color-1-700,
    color-1-900: $color-1-900,
    
    color-2-50: $color-2-50,
    color-2-100: $color-2-100,
    color-2-300: $color-2-300,
    color-2-500: $color-2-500,
    color-2-700: $color-2-700,
    color-2-900: $color-2-900,
);

$shades: (
    "white": $white,
    grey-1-50: $grey-1-50,
    grey-1-100: $grey-1-100,
    grey-1-300: $grey-1-300,
    grey-1-500: $grey-1-500,
    grey-1-700: $grey-1-700,
    grey-1-900: $grey-1-900,
    "black": $black,
);

$alerts: (
    info-50: $support-info-50,
    info-100: $support-info-100,
    info-300: $support-info-300,
    info-500: $support-info-500,
    info-700: $support-info-700,
    info-900: $support-info-900,
    success-50: $support-success-50,
    success-100: $support-success-100,
    success-300: $support-success-300,
    success-500: $support-success-500,
    success-700: $support-success-700,
    success-900: $support-success-900,
    error-50: $support-error-50,
    error-100: $support-error-100,
    error-300: $support-error-300,
    error-500: $support-error-500,
    error-700: $support-error-700,
    error-900: $support-error-900,
    warning-50: $support-warning-50,
    warning-100: $support-warning-100,
    warning-300: $support-warning-300,
    warning-500: $support-warning-500,
    warning-700: $support-warning-700,
    warning-900: $support-warning-900,
);
