$modal-size-md-max-width: 1062px;
$modal-size-sm-max-width: 526px;
$white: white;

.modal {
    display: none;

    &.hide {
        visibility: hidden;
    }

    &.is-open {
        display: block;
    }

    &.size-full .modal-content-inner {
        width: 100%;
        height: 100%;
    }

    // &.size-lg .modal-content-inner {
    //     @include container;
    // }

    &.size-md .modal-content-inner {
        max-width: $modal-size-md-max-width;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        background-color: $white;
    }

    &.size-sm .modal-content-inner {
        margin: $spacing-5;
        max-width: $modal-size-sm-max-width;
        background-color: $white;
        position: relative;
    }

    &.size-md,
    &.size-sm {
        .modal-close-btn-wrapper {
            position: absolute;
            top: $spacing-4;
            right: $spacing-4;

            .modal-close {
                width: auto;
                height: auto;
                padding: $spacing-2;
            }

            .svg-icon {
                color: $color-1-500;
            }
        }
    }

    &.align-top {
        .modal-scroll {
            align-items: flex-start;
        }

        .modal-container {
            width: 100%;
        }

        .modal-content {
            padding: $spacing-5;
            vertical-align: top;
        }
    }

    .modal-content-inner {
        position: relative;
    }

    &[aria-hidden="false"] {
        .modal-overlay {
            animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
        }

        // .modal-container {
        //     animation: showScale 0.3s cubic-bezier(0, 0, 0.2, 1);
        // }
    }

    &[aria-hidden="true"] {
        .modal-overlay {
            animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
        }

        // .modal-container {
        //     animation: hideScale 0.3s cubic-bezier(0, 0, 0.2, 1);
        // }
    }

    .modal-container,
    .modal-overlay {
        will-change: transform;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($black, 0.8);
        z-index: 9999;
    }

    .modal-scroll {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow-y: auto;
        position: relative;
    }

    .modal-container {
        display: table;
        height: 100%;
        overflow-x: hidden;
    }

    .modal-content {
        display: table-cell;
        vertical-align: middle;
        max-width: 100vw;
    }

    .modal-close-btn-wrapper {
        position: fixed;
        top: $spacing-4;
        right: $spacing-4;
    }

    .modal-close {
        // @include button-reset;
        // @include transition(opacity);
        // @include modal-close-font-size;
        color: $white;
        border: 0;
        cursor: pointer;
        position: relative;
        z-index: 1;
        opacity: 0.8;
        width: $spacing-5;
        height: $spacing-5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;

        &:hover {
            opacity: 1;
        }

        .svg-icon {
            pointer-events: none;
        }

        // changed using svg icon, just keep this just in case
        // &::before,
        // &::after {
        //     position: absolute;
        //     content: '';
        //     width: 100%;
        //     height: 2px; /* cross thickness */
        //     background-color: $white;
        //     display: block;
        // }

        // &::before {
        //     transform: rotate(45deg) scale(1);
        // }

        // &::after {
        //     transform: rotate(-45deg) scale(1);
        // }
    }
}

@media (min-width: $screen-sm) {
    .modal {
        &.size-sm .modal-content-inner {
            min-width: $modal-size-sm-max-width;
        }
    }
}

@media (min-width: $screen-md) {
    .modal {
        .modal-close-btn-wrapper {
            top: $spacing-5;
            right: $spacing-5;
        }
    }
}

@media (min-width: $screen-lg) {
    .modal {
        &.align-top .modal-content {
            padding-top: $spacing-12;
        }
    }
}

// add class when modal is opened
body.modal-opened {
    overflow: hidden;
}

@mixin modal-appear-animation($animationValue) {
    .modal[aria-hidden="false"] & {
        animation: $animationValue;
    }
}

@mixin modal-hide-animation($animationValue) {
    .modal[aria-hidden="true"] & {
        animation: $animationValue;
    }
}
