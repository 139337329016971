.reset-animation {
  padding: $spacing-4; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;

  button {
    appearance: none;
    background-color: $color-1-500;
    color: $white;
    border: 2px solid $color-1-500;
    padding: $spacing-4 $spacing-5;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: $color-1-700;
    }
  }
}
