.progress-bar-wrapper {
  min-width: 300px;
}

.progress-bar-header {
  display: flex;
  background: $color-2-700;
  color: $white;
  padding: $spacing-2 $spacing-3;
  align-items: center;

  .close-button {
    @include button-reset;
    color: black;
    margin-left: auto;
    background-color: $grey-1-100;
    width: $spacing-5;
    height: $spacing-5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      background-color: $grey-1-50;
    }
  }
}

.progress-bar-content {
  padding: $spacing-5;
}

.progress-bar {
  border: 1px solid $color-2-300;
  height: $spacing-4;
  margin-left: $spacing-6;
  margin-right: $spacing-6;
}

.progress-bar-fill {
  height: $spacing-4;
  transition: width 0ms ease-in;
}

.progress-bar-text {
  text-align: center;
  margin-top: $spacing-5;

  .svg-icon {
    width: $spacing-5;
    height: $spacing-5;
    color: $support-success-500;
    margin-bottom: -$spacing-1;
    margin-left: $spacing-1;
  }
}
