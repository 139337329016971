@mixin transition($properties: all, $duration: 400ms, $method: ease, $delay: 0ms) {
    $value: ();
    @each $property in $properties {
        @if length($value) > 0 {
            $value: append($value, unquote(",") #{$property} $duration $method $delay);
        }
        @else {
            $value: append($value, #{$property} $duration $method $delay);
        }
    }
    transition: $value;
}

@keyframes showScale {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
    }
}

@keyframes hideScale {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slideFadeLeftIn {
    0% {
        transform: translateX($spacing-8);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideFadeRightOut {
    0% {
        opacity: 1;
    }

    100% {
        transform: translateX($spacing-8);
        opacity: 0;
    }
}

@keyframes slideFadeRightIn {
    0% {
        transform: translateX(-$spacing-8);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideFadeLeftOut {
    0% {
        opacity: 1;
    }

    100% {
        transform: translateX(-$spacing-8);
        opacity: 0;
    }
}

@keyframes slideFadeRightIn {
    0% {
        transform: translateX(-$spacing-8);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideFadeDownIn {
    0% {
        transform: translateY(-$spacing-4);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

$move-jump-duration: 0.6s;
$move-jump-size: $spacing-2;

@keyframes move-jump-horz {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX($move-jump-size);
    }

    100% {
        transform: translateX(0);
    }
}

@mixin move-jump-horz {
    animation-name: move-jump-horz;
    animation-duration: $move-jump-duration;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

@keyframes ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    // Can not 100% directly as Safari bug that cause flickering
    95% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0;
    }

    100% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
}

@keyframes ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

@keyframes pop {
    0% { transform: translate(0, 0) scale(1.25, 0.75); }
    50% { transform: translate(0, -100%) scale(1, 1); }
    55% { transform: translate(0, -100%) rotate(15deg); }
    60% { transform: translate(0, -100%) rotate(-15deg); }
    65% { transform: translate(0, -100%) rotate(15deg); }
    70% { transform: translate(0, -100%) rotate(-15deg); }
    100% { transform: translate(0, 0) scale(1.25, 0.75); }
}

@keyframes animateIn {
    0% {
        opacity: 0;
        transform: scale(0.6) translateY(-8px);
    }

    100% {
        opacity: 1;
    }
}

@mixin staggered-animate-in {
    animation-name: animateIn;
    animation-duration: 150ms;
    animation-delay: calc(var(--animation-order) * 36ms);
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}
