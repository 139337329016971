/* Modern CSS Reset
 *  https://github.com/hankchizljaw/modern-css-reset
 *  https://hankchizljaw.com/wrote/a-modern-css-reset/
*/

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
    height: auto;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/*  Addition rules pulled from Sanitize.css
 *  https://github.com/csstools/sanitize.css
 *  https://github.com/csstools/sanitize.css/blob/master/sanitize.css
*/

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

iframe {
    border-style: none;
}

/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
    -ms-touch-action: manipulation; /* 1 */
    touch-action: manipulation; /* 2 */
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
    cursor: progress;
}

/*
   * Change the cursor on control elements in all browsers (opinionated).
   */

[aria-controls] {
    cursor: pointer;
}

/*
   * Change the cursor on disabled, not-editable, or otherwise
   * inoperable elements in all browsers (opinionated).
   */

[aria-disabled="true"],
[disabled] {
    cursor: not-allowed;
}

/*
   * Change the display on visually hidden accessible elements
   * in all browsers (opinionated).
   */

[aria-hidden="false"][hidden] {
    display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    position: absolute;
}
